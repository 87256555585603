import axios from "axios";
import {
  CREATE_TICKET_FAIL,
  CREATE_TICKET_REQUEST,
  CREATE_TICKET_SUCCESS,
  PENDING_TICKET_FAIL,
  PENDING_TICKET_REQUEST,
  PENDING_TICKET_SUCCESS,
  SOLVED_TICKET_FAIL,
  SOLVED_TICKET_REQUEST,
  SOLVED_TICKET_SUCCESS,
  CANCELLED_TICKET_FAIL,
  CANCELLED_TICKET_REQUEST,
  CANCELLED_TICKET_SUCCESS,
  ALL_TICKET_FAIL,
  ALL_TICKET_REQUEST,
  ALL_TICKET_SUCCESS,
  PRIORITY_FAIL,
  PRIORITY_REQUEST,
  PRIORITY_SUCCESS,
  TICKET_TYPE_FAIL,
  TICKET_TYPE_REQUEST,
  TICKET_TYPE_SUCCESS,
} from "../../constants/user/ticketsConstants";
import { BASE_URL } from "../../config";

export const addNewTicket = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_TICKET_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.jwtToken}`,
      },
    };
    const { data } = await axios.post(
      `${BASE_URL}/api/Helpdesk/Ticket`,
      config
    );

    dispatch({
      type: CREATE_TICKET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_TICKET_FAIL,
      payload:
        error.response && error.response.data.responseMessage
          ? error.response.data.responseMessage
          : error.message,
    });
  }
};

export const getAllTickets = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ALL_TICKET_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.jwtToken}`,
      },
    };
    const { data } = await axios.get(
      `${BASE_URL}/api/Helpdesk/Ticket/TotalTicketCreated`,
      config
    );

    dispatch({
      type: ALL_TICKET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_TICKET_FAIL,
      payload:
        error.response && error.response.data.responseMessage
          ? error.response.data.responseMessage
          : error.message,
    });
  }
};

export const getPendingTickets = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PENDING_TICKET_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.jwtToken}`,
      },
    };
    const { data } = await axios.get(
      `${BASE_URL}/api/Helpdesk/Ticket/TotalTicketPending`,
      config
    );

    dispatch({
      type: PENDING_TICKET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PENDING_TICKET_FAIL,
      payload:
        error.response && error.response.data.responseMessage
          ? error.response.data.responseMessage
          : error.message,
    });
  }
};

export const getSolvedTickets = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SOLVED_TICKET_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.jwtToken}`,
      },
    };
    const { data } = await axios.get(
      `${BASE_URL}/api/Helpdesk/Ticket/TotalTicketSolved`,
      config
    );

    dispatch({
      type: SOLVED_TICKET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SOLVED_TICKET_FAIL,
      payload:
        error.response && error.response.data.responseMessage
          ? error.response.data.responseMessage
          : error.message,
    });
  }
};

export const getCancelTickets = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CANCELLED_TICKET_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.jwtToken}`,
      },
    };
    const { data } = await axios.get(
      `${BASE_URL}/api/Helpdesk/Ticket/TotalTicketCancelled`,
      config
    );

    dispatch({
      type: CANCELLED_TICKET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CANCELLED_TICKET_FAIL,
      payload:
        error.response && error.response.data.responseMessage
          ? error.response.data.responseMessage
          : error.message,
    });
  }
};

export const fetchFormPriority = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRIORITY_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.jwtToken}`,
      },
    };
    const { data } = await axios.get(
      `${BASE_URL}/api/helpdesk/admin/Priority/`,
      config
    );

    dispatch({
      type: PRIORITY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRIORITY_FAIL,
      payload:
        error.response && error.response.data.responseMessage
          ? error.response.data.responseMessage
          : error.message,
    });
  }
};

export const fetchFormTickets = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TICKET_TYPE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.jwtToken}`,
      },
    };
    const { data } = await axios.get(
      `${BASE_URL}/api/helpdesk/admin/TicketType/`,
      config
    );

    dispatch({
      type: TICKET_TYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TICKET_TYPE_FAIL,
      payload:
        error.response && error.response.data.responseMessage
          ? error.response.data.responseMessage
          : error.message,
    });
  }
};
