import React, { useState } from "react";
import { Header, Input, Button } from "../../../../components";
import { Link } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles.module.css";
import { FaUnlockAlt } from "react-icons/fa";
import { forgotMyPassword } from "../../../../redux/actions/user/authActions";
import { FORGOT_PASSWORD_RESET } from "../../../../redux/constants/user/authConstants";

const ForgotPassword = () => {
  // Helpers
  const dispatch = useDispatch();
  const toast = useToast();

  //   Get info from state
  const forgotPassword = useSelector((state) => state.forgotPassword);
  const { loading, error, success } = forgotPassword;

  const [email, setEmail] = useState("");

  //   Submit form
  const submitHandler = (e) => {
    e.preventDefault();
    if (!email) {
      toast({
        title: "Warning!",
        description: "Please enter email",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      dispatch(forgotMyPassword(email));
    }
  };

  //   error
  if (error) {
    toast({
      title: "Error!",
      description: error,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
    dispatch({ type: FORGOT_PASSWORD_RESET });
  }

  //   redirect to login if successful
  if (success) {
    toast({
      title: "Success!",
      description: "An email has been sent to your account",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    dispatch({ type: FORGOT_PASSWORD_RESET });
  }
  return (
    <div>
      <Header />
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <div className={styles.title}>
            <h3>Forgot Password</h3>
            <p>Please enter your Email Address</p>
          </div>

          <form onSubmit={submitHandler}>
            <Input
              Icon={FaUnlockAlt}
              type="email"
              title="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Button
              type="submit"
              title="Forgot Password"
              isFullWidth={true}
              loading={loading}
              color="gold"
            />
            <div className={`${styles.left}`}>
              <Link to="/login">Already have an account? Login</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
