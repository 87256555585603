export const CREATE_TICKET_REQUEST = "CREATE_TICKET_REQUEST";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAIL = "CREATE_TICKET_FAIL";
export const CREATE_TICKET_RESET = "CREATE_TICKET_RESET";

export const PENDING_TICKET_REQUEST = "PENDING_TICKET_REQUEST";
export const PENDING_TICKET_SUCCESS = "PENDING_TICKET_SUCCESS";
export const PENDING_TICKET_FAIL = "PENDING_TICKET_FAIL";
export const PENDING_TICKET_RESET = "PENDING_TICKET_RESET";

export const SOLVED_TICKET_REQUEST = "SOLVED_TICKET_REQUEST";
export const SOLVED_TICKET_SUCCESS = "SOLVED_TICKET_SUCCESS";
export const SOLVED_TICKET_FAIL = "SOLVED_TICKET_FAIL";
export const SOLVED_TICKET_RESET = "SOLVED_TICKET_RESET";

export const CANCELLED_TICKET_REQUEST = "CANCELLED_TICKET_REQUEST";
export const CANCELLED_TICKET_SUCCESS = "CANCELLED_TICKET_SUCCESS";
export const CANCELLED_TICKET_FAIL = "CANCELLED_TICKET_FAIL";
export const CANCELLED_TICKET_RESET = "CANCELLED_TICKET_RESET";

export const ALL_TICKET_REQUEST = "ALL_TICKET_REQUEST";
export const ALL_TICKET_SUCCESS = "ALL_TICKET_SUCCESS";
export const ALL_TICKET_FAIL = "ALL_TICKET_FAIL";
export const ALL_TICKET_RESET = "ALL_TICKET_RESET";

export const TICKET_TYPE_REQUEST = "TICKET_TYPE_REQUEST";
export const TICKET_TYPE_SUCCESS = "TICKET_TYPE_SUCCESS";
export const TICKET_TYPE_FAIL = "TICKET_TYPE_FAIL";
export const TICKET_TYPE_RESET = "TICKET_TYPE_RESET";

export const PRIORITY_REQUEST = "PRIORITY_REQUEST";
export const PRIORITY_SUCCESS = "PRIORITY_SUCCESS";
export const PRIORITY_FAIL = "PRIORITY_FAIL";
export const PRIORITY_RESET = "PRIORITY_RESET";
