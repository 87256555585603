import React, { useState, Suspense } from 'react'
import styles from './styles.module.css'
import { AiTwotoneAppstore, AiOutlineAppstore, AiOutlineClose } from 'react-icons/ai'
import { FaSignOutAlt, FaUsersCog, FaUserLock, FaCogs } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import logo from '../../assets/logo.png'
import { CircularProgress } from '@chakra-ui/react'
import { adminLogout } from '../../redux/actions/admin/authActions'

const AdminNavigation = () => {
    const dispatch = useDispatch()
    const [mtoggle, setMToggle] = useState(false)

    const logoutHandler = () => {
        dispatch(adminLogout())
    }
    return (
        <Suspense fallback={CircularProgress}>
            <div className={styles.bar}>{mtoggle ? <AiOutlineClose onClick={() => setMToggle(false)} /> : <AiOutlineAppstore onClick={() => setMToggle(true)} />}</div>
            <div className={mtoggle ? styles.navMobile : styles.navigation}>
                <div className={styles.logo}>
                    <img src={logo} alt="company logo" />
                    <h3>Helpdesk</h3>
                </div>
                {/* <div className={styles.name}>
                    <h5>Hello {user.fullName}!</h5>
                </div> */}
                <div className={styles.url}>
                    <ul>
                        <Link to="/admin/dashboard">
                            <li><AiTwotoneAppstore />Dashboard</li>
                        </Link>
                        <Link to="/admin/create">
                            <li><FaUserLock />Add Admin</li>
                        </Link>
                        <Link to="/admin/roles">
                            <li><FaUsersCog />Roles</li>
                        </Link>
                        <Link to="/admin/config">
                            <li><FaCogs />Configurations</li>
                        </Link>




                        <Link to="/#" onClick={logoutHandler}>
                            <li><FaSignOutAlt />Logout</li>
                        </Link>
                    </ul>
                </div>
            </div>
        </Suspense>
    )
}

export default AdminNavigation