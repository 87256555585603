export const CREATE_STAGE_REQUEST = "CREATE_STAGE_REQUEST";
export const CREATE_STAGE_SUCCESS = "CREATE_STAGE_SUCCESS";
export const CREATE_STAGE_FAIL = "CREATE_STAGE_FAIL";
export const CREATE_STAGE_RESET = "CREATE_STAGE_RESET";

export const GET_STAGE_REQUEST = "GET_STAGE_REQUEST";
export const GET_STAGE_SUCCESS = "GET_STAGE_SUCCESS";
export const GET_STAGE_FAIL = "GET_STAGE_FAIL";
export const GET_STAGE_RESET = "GET_STAGE_RESET";

export const GET_STAGES_REQUEST = "GET_STAGES_REQUEST";
export const GET_STAGES_SUCCESS = "GET_STAGES_SUCCESS";
export const GET_STAGES_FAIL = "GET_STAGES_FAIL";
export const GET_STAGES_RESET = "GET_STAGES_RESET";

export const UPDATE_STAGE_REQUEST = "UPDATE_STAGE_REQUEST";
export const UPDATE_STAGE_SUCCESS = "UPDATE_STAGE_SUCCESS";
export const UPDATE_STAGE_FAIL = "UPDATE_STAGE_FAIL";
export const UPDATE_STAGE_RESET = "UPDATE_STAGE_RESET";

export const DELETE_STAGE_REQUEST = "DELETE_STAGE_REQUEST";
export const DELETE_STAGE_SUCCESS = "DELETE_STAGE_SUCCESS";
export const DELETE_STAGE_FAIL = "DELETE_STAGE_FAIL";
export const DELETE_STAGE_RESET = "DELETE_STAGE_RESET";
