import React, { useState, useEffect } from "react";
import { Header, Input, Button } from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles.module.css";
import { FaEnvelope, FaEyeSlash } from "react-icons/fa";
import { USER_LOGOUT } from "../../../../redux/constants/user/authConstants";
import { userAuth } from "../../../../redux/actions/user/authActions";

const Login = () => {
  // Helpers
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   Get info from state
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  // Form Fields OnChange
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Login Handler
  const loginHandler = (e) => {
    e.preventDefault();
    dispatch(userAuth(email, password));
  };

  //   error
  if (error) {
    toast({
      title: "Error!",
      description: error,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
    dispatch({ type: USER_LOGOUT });
  }

  //   redirect to dashboard if login successful
  useEffect(() => {
    if (userInfo) {
      navigate("/user/dashboard");
    }
  }, [navigate, userInfo]);

  return (
    <div>
      <Header />
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <div className={styles.title}>
            <h3>Hello There</h3>
            <p>Please enter your correct details</p>
          </div>

          <form onSubmit={loginHandler}>
            <Input
              Icon={FaEnvelope}
              type="text"
              value={email}
              title="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              Icon={FaEyeSlash}
              type="password"
              value={password}
              title="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className={`${styles.right}`}>
              <Link to="/forgotPassword">Forgot Password?</Link>
            </div>

            <Button
              type="submit"
              title="Login"
              isFullWidth={true}
              loading={loading}
              color="gold"
            />
            <div className={`${styles.left}`}>
              <Link to="/register">Don't have an account? Create one</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
