import React, { useEffect } from "react";
import { Header } from "../../../components";
import { useNavigate } from "react-router-dom";
import { useToast, CircularProgress, Center } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../User/Auth/styles.module.css";
import MicrosoftLogin from "react-microsoft-login";
import { ADMIN_LOGOUT } from "../../../redux/constants/admin/authConstants";
import { adminAuth } from "../../../redux/actions/admin/authActions";

const AdminLogin = () => {
  // Helpers
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   Microsoft SSO
  const authHandler = (err, data, msal) => {
    if (err) {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      sessionStorage.clear();
    }
    if (data) {
      const accessToken = data.accessToken;
      dispatch(adminAuth(accessToken));
      sessionStorage.clear();
    }
  };

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo, error, loading } = adminLogin;

  // if token exist redirect to dashboard
  useEffect(() => {
    if (adminInfo) {
      navigate("/admin/dashboard");
    }
  }, [navigate, adminInfo]);

  // if error login
  if (error) {
    toast({
      title: "Error",
      description: error,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
    dispatch({ type: ADMIN_LOGOUT });
  }

  return (
    <div>
      <Header />
      <div className={styles.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className={styles.title}>
            <h3>Hello There</h3>
            <p>Please Click on the button to sign in</p>
          </div>

          {loading ? (
            <Center>
              <CircularProgress isIndeterminate color="#087E8C" />
            </Center>
          ) : (
            <MicrosoftLogin
              clientId="ee241df3-3ed6-424f-95cb-2b58de8f5e07"
              authCallback={authHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
