export const CREATE_TICKET_REQUEST = "CREATE_TICKET_REQUEST";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAIL = "CREATE_TICKET_FAIL";
export const CREATE_TICKET_RESET = "CREATE_TICKET_RESET";

export const GET_TICKET_REQUEST = "GET_TICKET_REQUEST";
export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS";
export const GET_TICKET_FAIL = "GET_TICKET_FAIL";
export const GET_TICKET_RESET = "GET_TICKET_RESET";

export const GET_TICKETS_REQUEST = "GET_TICKETS_REQUEST";
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS";
export const GET_TICKETS_FAIL = "GET_TICKETS_FAIL";
export const GET_TICKETS_RESET = "GET_TICKETS_RESET";

export const UPDATE_TICKET_REQUEST = "UPDATE_TICKET_REQUEST";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_FAIL = "UPDATE_TICKET_FAIL";
export const UPDATE_TICKET_RESET = "UPDATE_TICKET_RESET";

export const DELETE_TICKET_REQUEST = "DELETE_TICKET_REQUEST";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const DELETE_TICKET_FAIL = "DELETE_TICKET_FAIL";
export const DELETE_TICKET_RESET = "DELETE_TICKET_RESET";
