import React, { useState } from "react";
import { Header, Input, Button } from "../../../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles.module.css";
import { FaShieldAlt } from "react-icons/fa";
import { verification } from "../../../../redux/actions/user/authActions";
import { VERIFY_ACCOUNT_RESET } from "../../../../redux/constants/user/authConstants";

const Verification = () => {
  // Helpers
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const { email } = useParams();

  //   Get info from state
  const verifyAccount = useSelector((state) => state.verifyAccount);
  const { loading, error, success } = verifyAccount;

  const [token, setToken] = useState("");

  //   Submit form
  const submitHandler = (e) => {
    e.preventDefault();
    if (!email) {
      toast({
        title: "Warning!",
        description: "Please enter email",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      dispatch(verification(email, token));
    }
  };

  //   error
  if (error) {
    toast({
      title: "Error!",
      description: error,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
    dispatch({ type: VERIFY_ACCOUNT_RESET });
  }

  //   redirect to login if successful
  if (success) {
    toast({
      title: "Success!",
      description: "Verification Successful",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    dispatch({ type: VERIFY_ACCOUNT_RESET });
    navigate("/login");
  }

  return (
    <div>
      <Header />
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <div className={styles.title}>
            <h3>Account Verification</h3>
            <p>Enter Token</p>
          </div>

          <form onSubmit={submitHandler}>
            <Input
              Icon={FaShieldAlt}
              type="text"
              title="Token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />

            <Button
              type="submit"
              title="Verify Account"
              isFullWidth={true}
              loading={loading}
              color="teal"
            />
            <div className={`${styles.left}`}>
              <Link to="/login">Already have an account? Login</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Verification;
