export const CREATE_TAG_REQUEST = "CREATE_TAG_REQUEST";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAIL = "CREATE_TAG_FAIL";
export const CREATE_TAG_RESET = "CREATE_TAG_RESET";

export const GET_TAG_REQUEST = "GET_TAG_REQUEST";
export const GET_TAG_SUCCESS = "GET_TAG_SUCCESS";
export const GET_TAG_FAIL = "GET_TAG_FAIL";
export const GET_TAG_RESET = "GET_TAG_RESET";

export const UPDATE_TAG_REQUEST = "UPDATE_TAG_REQUEST";
export const UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_FAIL = "UPDATE_TAG_FAIL";
export const UPDATE_TAG_RESET = "UPDATE_TAG_RESET";

export const DELETE_TAG_REQUEST = "DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAIL = "DELETE_TAG_FAIL";
export const DELETE_TAG_RESET = "DELETE_TAG_RESET";
