export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

export const GET_ME_REQUEST = "GET_ME_REQUEST";
export const GET_ME_SUCCESS = "GET_ME_SUCCESS";
export const GET_ME_FAIL = "GET_ME_FAIL";
export const GET_ME_RESET = "GET_ME_RESET";

export const CREATE_ADMIN_REQUEST = "CREATE_ADMIN_REQUEST";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAIL = "CREATE_ADMIN_FAIL";
export const CREATE_ADMIN_RESET = "CREATE_ADMIN_RESET";

export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAIL = "GET_ADMIN_FAIL";
export const GET_ADMIN_RESET = "GET_ADMIN_RESET";

export const DELETE_ADMIN_REQUEST = "DELETE_ADMIN_REQUEST";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL";
export const DELETE_ADMIN_RESET = "DELETE_ADMIN_RESET";

export const UPDATE_ADMIN_REQUEST = "UPDATE_ADMIN_REQUEST";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAIL = "UPDATE_ADMIN_FAIL";
export const UPDATE_ADMIN_RESET = "UPDATE_ADMIN_RESET";

export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";
export const GET_ROLES_RESET = "GET_ROLES_RESET";

export const UPDATE_ROLES_REQUEST = "UPDATE_ROLES_REQUEST";
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS";
export const UPDATE_ROLES_FAIL = "UPDATE_ROLES_FAIL";
export const UPDATE_ROLES_RESET = "UPDATE_ROLES_RESET";
