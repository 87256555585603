import React, { useState } from "react";
import { Header, Input, Button } from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles.module.css";
import { FaEnvelope, FaEyeSlash } from "react-icons/fa";
import { registration } from "../../../../redux/actions/user/authActions";
import { USER_REGISTER_RESET } from "../../../../redux/constants/user/authConstants";

const Register = () => {
  // Helpers
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  //   Get info from state
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, success } = userRegister;

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //   Submit form
  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast({
        title: "Warning!",
        description: "Password does not match",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      dispatch(registration(fullName, email, password, confirmPassword));
    }
  };

  //   error
  if (error) {
    toast({
      title: "Error!",
      description: error,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
    dispatch({ type: USER_REGISTER_RESET });
  }

  //   redirect to login if successful
  if (success) {
    toast({
      title: "Success!",
      description: "User Registration Successful",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    dispatch({ type: USER_REGISTER_RESET });
    navigate(`/verification/${email}`);
  }

  return (
    <div>
      <Header />
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <div className={styles.title}>
            <h3>Hello There</h3>
            <p>Please enter your correct details</p>
          </div>

          <form onSubmit={submitHandler}>
            <Input
              Icon={FaEnvelope}
              type="text"
              value={fullName}
              title="Full Name"
              onChange={(e) => setFullName(e.target.value)}
            />
            <Input
              Icon={FaEnvelope}
              type="text"
              value={email}
              title="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              Icon={FaEyeSlash}
              type="password"
              value={password}
              title="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              Icon={FaEyeSlash}
              type="password"
              value={confirmPassword}
              title="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className={`${styles.right}`}>
              <Link to="/forgotPassword">Forgot Password?</Link>
            </div>

            <Button
              type="submit"
              title="CREATE ACCOUNT"
              isFullWidth={true}
              loading={loading}
              color="teal"
            />
            <div className={`${styles.left}`}>
              <Link to="/login">Already have an account? Login</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
