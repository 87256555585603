export const CREATE_STAGE_LEVEL_REQUEST = "CREATE_STAGE_LEVEL_REQUEST";
export const CREATE_STAGE_LEVEL_SUCCESS = "CREATE_STAGE_LEVEL_SUCCESS";
export const CREATE_STAGE_LEVEL_FAIL = "CREATE_STAGE_LEVEL_FAIL";
export const CREATE_STAGE_LEVEL_RESET = "CREATE_STAGE_LEVEL_RESET";

export const GET_STAGE_LEVEL_REQUEST = "GET_STAGE_LEVEL_REQUEST";
export const GET_STAGE_LEVEL_SUCCESS = "GET_STAGE_LEVEL_SUCCESS";
export const GET_STAGE_LEVEL_FAIL = "GET_STAGE_LEVEL_FAIL";
export const GET_STAGE_LEVEL_RESET = "GET_STAGE_LEVEL_RESET";

export const GET_STAGES_LEVEL_REQUEST = "GET_STAGES_LEVEL_REQUEST";
export const GET_STAGES_LEVEL_SUCCESS = "GET_STAGES_LEVEL_SUCCESS";
export const GET_STAGES_LEVEL_FAIL = "GET_STAGES_LEVEL_FAIL";
export const GET_STAGES_LEVEL_RESET = "GET_STAGES_LEVEL_RESET";

export const UPDATE_STAGE_LEVEL_REQUEST = "UPDATE_STAGE_LEVEL_REQUEST";
export const UPDATE_STAGE_LEVEL_SUCCESS = "UPDATE_STAGE_LEVEL_SUCCESS";
export const UPDATE_STAGE_LEVEL_FAIL = "UPDATE_STAGE_LEVEL_FAIL";
export const UPDATE_STAGE_LEVEL_RESET = "UPDATE_STAGE_LEVEL_RESET";

export const DELETE_STAGE_LEVEL_REQUEST = "DELETE_STAGE_LEVEL_REQUEST";
export const DELETE_STAGE_LEVEL_SUCCESS = "DELETE_STAGE_LEVEL_SUCCESS";
export const DELETE_STAGE_LEVEL_FAIL = "DELETE_STAGE_LEVEL_FAIL";
export const DELETE_STAGE_LEVEL_RESET = "DELETE_STAGE_LEVEL_RESET";
