import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.module.css'

const Header = () => {
    return (
        <div className={styles.header}>
            <div className={styles.name}>LBAN HELPDESK PORTAL</div>
            <div className={styles.btnContainer}>
                <Link to="/admin" className='btn teal'>Admin</Link>
            </div>
        </div>
    )
}

export default Header