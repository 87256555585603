import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Login,
  Home,
  Register,
  Verification,
  ForgotPassword,
  Dashboard,
  Tickets,
  AdminLogin,
  AdminDashboard,
  Admin,
  Roles,
  Team,
  TicketType,
  Priority,
  Tag,
  ExcludeStage,
  Stages,
  StageLevel,
} from "./screens";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/register" exact element={<Register />} />
          <Route path="/verification/:email" exact element={<Verification />} />
          <Route path="/forgotPassword" exact element={<ForgotPassword />} />
          <Route path="/user/dashboard" exact element={<Dashboard />} />
          <Route path="/user/tickets/create" exact element={<Tickets />} />
          <Route path="/admin" exact element={<AdminLogin />} />
          <Route path="/admin/dashboard" exact element={<AdminDashboard />} />
          <Route path="/admin/create" exact element={<Admin />} />
          <Route path="/admin/roles" exact element={<Roles />} />
          <Route path="/admin/config" exact element={<Team />} />
          <Route path="/admin/config/tag" exact element={<Tag />} />
          <Route
            path="/admin/config/ticketType"
            exact
            element={<TicketType />}
          />
          <Route path="/admin/config/priority" exact element={<Priority />} />
          <Route
            path="/admin/config/excludeStage"
            exact
            element={<ExcludeStage />}
          />
          <Route path="/admin/config/stages" exact element={<Stages />} />
          <Route
            path="/admin/config/stagelevel"
            exact
            element={<StageLevel />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
