export const CREATE_TEAM_REQUEST = "CREATE_TEAM_REQUEST";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_FAIL = "CREATE_TEAM_FAIL";
export const CREATE_TEAM_RESET = "CREATE_TEAM_RESET";

export const GET_TEAM_REQUEST = "GET_TEAM_REQUEST";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_FAIL = "GET_TEAM_FAIL";
export const GET_TEAM_RESET = "GET_TEAM_RESET";

export const GET_TEAMS_REQUEST = "GET_TEAMS_REQUEST";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAIL = "GET_TEAMS_FAIL";
export const GET_TEAMS_RESET = "GET_TEAMS_RESET";

export const UPDATE_TEAM_REQUEST = "UPDATE_TEAM_REQUEST";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_FAIL = "UPDATE_TEAM_FAIL";
export const UPDATE_TEAM_RESET = "UPDATE_TEAM_RESET";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";
export const DELETE_TEAM_RESET = "DELETE_TEAM_RESET";
