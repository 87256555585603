import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../../components";
import styles from "./styles.module.css";

const Home = () => {
  return (
    <div>
      <Header />
      <div className={styles.container}>
        <p>Welcome to the</p>
        <h1>
          HELPDESK <br />
          PORTAL
        </h1>
        <div className="btnContainer">
          <Link to="/register" className="btn teal">
            Register
          </Link>
          <Link to="/login" className="btn gold">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
