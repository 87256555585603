import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { AiOutlineSearch } from 'react-icons/ai'
import { useSelector, useDispatch } from 'react-redux'
import { getMe } from '../../redux/actions/user/authActions'
import { useNavigate } from 'react-router-dom'
import { fetchAdminProfile } from '../../redux/actions/admin/authActions'

const Title = ({ title, type = "User" }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [search, setSearch] = useState("")

    const userProfile = useSelector((state) => state.userProfile)
    const { user = {} } = userProfile

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const adminLogin = useSelector((state) => state.adminLogin)
    const { adminInfo } = adminLogin

    const adminProfile = useSelector((state) => state.adminProfile)
    const { admin = {} } = adminProfile

    useEffect(() => {
        if (type === "Admin") {
            dispatch(fetchAdminProfile())
        }
        if (type === "User") {
            dispatch(getMe())
        }
    }, [dispatch, type])
    if (type === "User" && !userInfo) {
        navigate("/login")
    }
    if (type === "Admin" && !adminInfo) {
        navigate("/admin")
    }
    return (
        <div className={styles.pageTitle}>
            <div className={styles.title}>{title}</div>
            <div className={styles.name}>
                <div className={styles.search}>
                    <input type="text" placeholder='Search' value={search} onChange={(e) => setSearch(e.target.value)} />
                    <AiOutlineSearch />
                </div>
                <p>Welcome {type === "User" ? user && user.fullName : admin && admin.fullName}</p>
            </div>

        </div>
    )
}

export default Title