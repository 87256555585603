import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  authReducer,
  forgotReducer,
  meReducer,
  registerReducer,
  resetReducer,
  verifyReducer,
} from "./reducers/user/authReducers";
import {
  allTicketReducer,
  cancelledTicketReducer,
  createTicketReducer,
  getTicketTypeReducer,
  getUPriorityReducer,
  pendingTicketReducer,
  solvedTicketReducer,
} from "./reducers/user/ticketsReducer";
import {
  adminAuthReducer,
  adminMeReducer,
  createAdminReducer,
  deleteAdminReducer,
  getAdminReducer,
  getRolesReducer,
  updateAdminReducer,
  updateRolesReducer,
} from "./reducers/admin/authReducers";
import {
  createTeamReducer,
  deleteTeamReducer,
  getAllTeamsReducer,
  getTeamReducer,
  updateTeamReducer,
} from "./reducers/admin/teamReducers";

import {
  createATicketReducer,
  deleteTicketReducer,
  getAllTicketsReducer,
  getTicketReducer,
  updateTicketReducer,
} from "./reducers/admin/ticketReducers";

import {
  createPriorityReducer,
  deletePriorityReducer,
  getAllPrioritysReducer,
  getPriorityReducer,
  updatePriorityReducer,
} from "./reducers/admin/priorityReducers";
import {
  createTagReducer,
  deleteTagReducer,
  getTagReducer,
  updateTagReducer,
} from "./reducers/admin/tagReducers";
import {
  createEStageReducer,
  deleteEStageReducer,
  getAllEStagesReducer,
  getEStageReducer,
  updateEStageReducer,
} from "./reducers/admin/eStageReducers";
import {
  createStageReducer,
  deleteStageReducer,
  getAllStagesReducer,
  getStageReducer,
  updateStageReducer,
} from "./reducers/admin/stageReducers";
import {
  createStageLevelReducer,
  deleteStageLevelReducer,
  getAllStagesLevelReducer,
  getStageLevelReducer,
  updateStageLevelReducer,
} from "./reducers/admin/stageLevelReducers";

const reducer = combineReducers({
  userLogin: authReducer,
  userRegister: registerReducer,
  verifyAccount: verifyReducer,
  forgotPassword: forgotReducer,
  resetPassword: resetReducer,
  userProfile: meReducer,
  createTicket: createTicketReducer,
  pendingTicket: pendingTicketReducer,
  solvedTicket: solvedTicketReducer,
  allTicket: allTicketReducer,
  cancelledTicket: cancelledTicketReducer,
  getTicketType: getTicketTypeReducer,
  getPriorityReducer: getUPriorityReducer,

  adminLogin: adminAuthReducer,
  adminProfile: adminMeReducer,
  createAdmin: createAdminReducer,
  getAdmins: getAdminReducer,
  deleteAdmin: deleteAdminReducer,
  updateAdmin: updateAdminReducer,
  getRoles: getRolesReducer,
  updateRoles: updateRolesReducer,
  createTeam: createTeamReducer,
  getAllTeams: getAllTeamsReducer,
  getTeam: getTeamReducer,
  deleteTeam: deleteTeamReducer,
  updateTeam: updateTeamReducer,
  createATicket: createATicketReducer,
  getAllTickets: getAllTicketsReducer,
  getTicket: getTicketReducer,
  deleteTicket: deleteTicketReducer,
  updateTicket: updateTicketReducer,
  createPriority: createPriorityReducer,
  getAllPrioritys: getAllPrioritysReducer,
  getPriority: getPriorityReducer,
  deletePriority: deletePriorityReducer,
  updatePriority: updatePriorityReducer,
  createTag: createTagReducer,
  getTags: getTagReducer,
  deleteTag: deleteTagReducer,
  updateTag: updateTagReducer,
  createEStage: createEStageReducer,
  getAllEStages: getAllEStagesReducer,
  getEStage: getEStageReducer,
  deleteEStage: deleteEStageReducer,
  updateEStage: updateEStageReducer,
  createStage: createStageReducer,
  getAllStages: getAllStagesReducer,
  getStage: getStageReducer,
  deleteStage: deleteStageReducer,
  updateStage: updateStageReducer,
  createStageLevel: createStageLevelReducer,
  getAllStagesLevel: getAllStagesLevelReducer,
  getStageLevel: getStageLevelReducer,
  deleteStageLevel: deleteStageLevelReducer,
  updateStageLevel: updateStageLevelReducer,
});
const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
const adminInfoFromStorage = localStorage.getItem("adminInfo")
  ? JSON.parse(localStorage.getItem("adminInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  adminLogin: { adminInfo: adminInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
