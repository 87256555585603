import React, { useEffect, useState } from "react";
import {
  AdminNavigation,
  Title,
  Input,
  Button,
  Modal,
  MenuBar,
} from "../../../components";
// import styles from "./styles.module.css";
import swal from "sweetalert";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import { useToast, CircularProgress, Center } from "@chakra-ui/react";
import { FaWrench, FaTrashAlt } from "react-icons/fa";
import {
  addTicket,
  editTicket,
  fetchTickets,
  removeTicket,
} from "../../../redux/actions/admin/ticketActions";
import {
  CREATE_TICKET_RESET,
  DELETE_TICKET_RESET,
  UPDATE_TICKET_RESET,
} from "../../../redux/constants/admin/ticketConstants";

const TicketType = () => {
  const ptitle = "Ticket Type";
  const dispatch = useDispatch();
  const toast = useToast();
  //   const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [ticketTypeName, setTicketTypeName] = useState("");
  const [id, setID] = useState(null);

  useEffect(() => {
    dispatch(fetchTickets());
  }, [dispatch]);

  const openHandler = () => {
    setOpen(true);
    setEdit(false);
    setTicketTypeName("");
  };

  // states
  const createATicket = useSelector((state) => state.createATicket);
  const { loading, error, success } = createATicket;
  const updateTicket = useSelector((state) => state.updateTicket);
  const { loading: uLoading, error: uError, success: uSuccess } = updateTicket;
  const deleteTicket = useSelector((state) => state.deleteTicket);
  const { loading: dLoading, error: dError, success: dSuccess } = deleteTicket;
  const getAllTickets = useSelector((state) => state.getAllTickets);
  const { tickets = [] } = getAllTickets;

  const columns = [{ title: "Ticket Name", field: "ticketTypeName" }];

  const deleteHandler = (id) => {
    swal({
      title: "Are you sure?",
      text: `Are you sure you want to delete this ${ptitle}`,
      icon: "warning",
      dangerMode: true,
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(removeTicket(id));
      }
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!ticketTypeName) {
      toast({
        title: "Warning!",
        description: "Enter Ticket Name",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      dispatch(addTicket(ticketTypeName));
    }
  };

  const editHandler = (e) => {
    e.preventDefault();
    if (!ticketTypeName) {
      toast({
        title: "Warning!",
        description: "Enter Ticket Name",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      dispatch(editTicket(ticketTypeName, id));
    }
  };

  if (error || uError || dError) {
    toast({
      title: "Error",
      description: error || uError || dError,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
    error && dispatch({ type: CREATE_TICKET_RESET });
    uError && dispatch({ type: UPDATE_TICKET_RESET });
    dError && dispatch({ type: DELETE_TICKET_RESET });
  }

  if (success || uSuccess || dSuccess) {
    toast({
      title: "Notification",
      description: `Sucesss`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    setOpen(false);
    dispatch(fetchTickets());
    success && dispatch({ type: CREATE_TICKET_RESET });
    uSuccess && dispatch({ type: UPDATE_TICKET_RESET });
    dSuccess && dispatch({ type: DELETE_TICKET_RESET });
  }

  // Menubar Items
  const menu = [
    { name: "Helpdesk Teams", url: "/admin/config" },
    { name: "Ticket Type", active: true, url: "/admin/config/ticketType" },
    { name: "Stages", url: "/admin/config/stages" },
    { name: "Stage Level", url: "/admin/config/stagelevel" },
    { name: "SLA Policies", url: "/admin/config/sla" },
    { name: "Priority", url: "/admin/config/priority" },
    { name: "Tag", url: "/admin/config/tag" },
    { name: "Exclude Stage", url: "/admin/config/excludeStage" },
  ];

  return (
    <div className="appContainer">
      <AdminNavigation />
      <div className="contentsRight">
        <Title title={ptitle} type="Admin" />
        <MenuBar menu={menu} />
        <div className="btnContainer right">
          <button type="button" onClick={openHandler} className="btn teal">
            Add {ptitle}
          </button>
        </div>
        {dLoading ? (
          <Center>
            <CircularProgress isIndeterminate color="#3AB29A" />
          </Center>
        ) : (
          <MaterialTable
            title=""
            columns={columns}
            data={tickets}
            options={{
              exportAllData: true,
              exportButton: true,
              actionsCellStyle: {
                backgroundColor: "none",
                color: "#FF00dd",
              },
              actionsColumnIndex: -1,

              headerStyle: {
                backgroundColor: "#dcdcdc",
                color: "black",
                fontWeight: "bold",
              },
            }}
            style={{
              boxShadow: "none",
              width: "100%",
              background: "none",
              fontSize: "13px",
            }}
            actions={[
              {
                icon: "launch",
                iconProps: { style: { fontSize: "20px", color: "gold" } },
                tooltip: "Update",
                onClick: (event, rowData) => {
                  setOpen(true);
                  setEdit(true);
                  setTicketTypeName(rowData.ticketTypeName);
                  setID(rowData.id);
                },
                title: "Update",
                color: "teal",
                Icon: FaWrench,
              },
              {
                icon: "launch",
                iconProps: { style: { fontSize: "20px", color: "gold" } },
                tooltip: "Delete",
                onClick: (event, rowData) => {
                  deleteHandler(rowData.id);
                },
                title: "Delete",
                color: "gold",
                Icon: FaTrashAlt,
              },
            ]}
            components={{
              Action: (props) => (
                <button
                  onClick={(event) => props.action.onClick(event, props.data)}
                  className={`btnTable ${props.action.color}`}
                >
                  <props.action.Icon />
                  {props.action.title}
                </button>
              ),
            }}
          />
        )}
        <Modal
          isVisible={open}
          title={edit ? `Update ${ptitle}` : `Add ${ptitle}`}
          size="lg"
          content={
            <form onSubmit={edit ? editHandler : submitHandler}>
              <div className="nirsal__InputFlex">
                <Input
                  type="text"
                  onChange={(e) => setTicketTypeName(e.target.value)}
                  value={ticketTypeName}
                  required={true}
                  title="Name"
                  size="large"
                />
                <Button
                  title={edit ? `Update ${ptitle}` : `Add ${ptitle}`}
                  type="submit"
                  isFullWidth={true}
                  size="lg"
                  loading={loading || uLoading}
                />
              </div>
            </form>
          }
          onClose={() => setOpen(false)}
        />
      </div>
    </div>
  );
};

export default TicketType;
