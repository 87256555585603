import React, { useEffect, useState } from "react";
import {
  AdminNavigation,
  Title,
  Input,
  Button,
  Modal,
  MenuBar,
  Textarea,
  Select,
} from "../../../components";
// import styles from "./styles.module.css";
import swal from "sweetalert";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import { useToast, CircularProgress, Center } from "@chakra-ui/react";
import { FaWrench, FaTrashAlt } from "react-icons/fa";
import {
  addStage,
  editStage,
  fetchStages,
  removeStage,
} from "../../../redux/actions/admin/stageActions";
import {
  CREATE_STAGE_RESET,
  DELETE_STAGE_RESET,
  UPDATE_STAGE_RESET,
} from "../../../redux/constants/admin/stageConstants";
import { fetchTeams } from "../../../redux/actions/admin/teamActions";

const Stages = () => {
  const ptitle = "Stage";
  const dispatch = useDispatch();
  const toast = useToast();
  //   const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [stageName, setStageName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [emailTemplate, setEmailTemplate] = useState("");
  const [description, setDescription] = useState("");
  const [id, setID] = useState(null);

  useEffect(() => {
    dispatch(fetchStages());
    dispatch(fetchTeams());
  }, [dispatch]);

  const openHandler = () => {
    setOpen(true);
    setEdit(false);
    setStageName("");
    setTeamId("");
    setEmailTemplate("");
    setDescription("");
  };

  // states
  const createStage = useSelector((state) => state.createStage);
  const { loading, error, success } = createStage;
  const updateStage = useSelector((state) => state.updateStage);
  const { loading: uLoading, error: uError, success: uSuccess } = updateStage;
  const deleteStage = useSelector((state) => state.deleteStage);
  const { loading: dLoading, error: dError, success: dSuccess } = deleteStage;
  const getAllStages = useSelector((state) => state.getAllStages);
  const { stages = [] } = getAllStages;
  const getAllTeams = useSelector((state) => state.getAllTeams);
  const { teams = [] } = getAllTeams;

  const columns = [
    { title: "Stage Name", field: "stageName" },
    { title: "Email Template", field: "emailTemplate" },
    { title: "Description", field: "description" },
  ];

  const deleteHandler = (id) => {
    swal({
      title: "Are you sure?",
      text: `Are you sure you want to delete this ${ptitle}`,
      icon: "warning",
      dangerMode: true,
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(removeStage(id));
      }
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!stageName || !teamId || !emailTemplate || !description) {
      toast({
        title: "Warning!",
        description: "All Fields are required",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      dispatch(addStage(stageName, teamId, emailTemplate, description));
    }
  };

  const editHandler = (e) => {
    e.preventDefault();
    if (!stageName || !teamId || !emailTemplate || !description) {
      toast({
        title: "Warning!",
        description: "All Fields are required",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      dispatch(editStage(stageName, teamId, emailTemplate, description, id));
    }
  };

  if (error || uError || dError) {
    toast({
      title: "Error",
      description: error || uError || dError,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
    error && dispatch({ type: CREATE_STAGE_RESET });
    uError && dispatch({ type: UPDATE_STAGE_RESET });
    dError && dispatch({ type: DELETE_STAGE_RESET });
  }

  if (success || uSuccess || dSuccess) {
    toast({
      title: "Notification",
      description: `Sucesss`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    setOpen(false);
    dispatch(fetchStages());
    success && dispatch({ type: CREATE_STAGE_RESET });
    uSuccess && dispatch({ type: UPDATE_STAGE_RESET });
    dSuccess && dispatch({ type: DELETE_STAGE_RESET });
  }

  // Menubar Items
  const menu = [
    { name: "Helpdesk Teams", url: "/admin/config" },
    { name: "Ticket Type", url: "/admin/config/ticketType" },
    { name: "Stages", url: "/admin/config/stages", active: true },
    { name: "Stage Level", url: "/admin/config/stagelevel" },
    { name: "SLA Policies", url: "/admin/config/sla" },
    { name: "Priority", url: "/admin/config/priority" },
    { name: "Tag", url: "/admin/config/tag" },
    { name: "Exclude Stage", url: "/admin/config/excludeStage" },
  ];

  return (
    <div className="appContainer">
      <AdminNavigation />
      <div className="contentsRight">
        <Title title={ptitle} type="Admin" />
        <MenuBar menu={menu} />
        <div className="btnContainer right">
          <button type="button" onClick={openHandler} className="btn teal">
            Add {ptitle}
          </button>
        </div>
        {dLoading ? (
          <Center>
            <CircularProgress isIndeterminate color="#3AB29A" />
          </Center>
        ) : (
          <MaterialTable
            title=""
            columns={columns}
            data={stages}
            options={{
              exportAllData: true,
              exportButton: true,
              actionsCellStyle: {
                backgroundColor: "none",
                color: "#FF00dd",
              },
              actionsColumnIndex: -1,

              headerStyle: {
                backgroundColor: "#dcdcdc",
                color: "black",
                fontWeight: "bold",
              },
            }}
            style={{
              boxShadow: "none",
              width: "100%",
              background: "none",
              fontSize: "13px",
            }}
            actions={[
              {
                icon: "launch",
                iconProps: { style: { fontSize: "20px", color: "gold" } },
                tooltip: "Update",
                onClick: (event, rowData) => {
                  setOpen(true);
                  setEdit(true);
                  setStageName(rowData.stageName);
                  setTeamId(rowData.teamId);
                  setEmailTemplate(rowData.emailTemplate);
                  setDescription(rowData.description);
                  setID(rowData.id);
                },
                title: "Update",
                color: "teal",
                Icon: FaWrench,
              },
              {
                icon: "launch",
                iconProps: { style: { fontSize: "20px", color: "gold" } },
                tooltip: "Delete",
                onClick: (event, rowData) => {
                  deleteHandler(rowData.id);
                },
                title: "Delete",
                color: "gold",
                Icon: FaTrashAlt,
              },
            ]}
            components={{
              Action: (props) => (
                <button
                  onClick={(event) => props.action.onClick(event, props.data)}
                  className={`btnTable ${props.action.color}`}
                >
                  <props.action.Icon />
                  {props.action.title}
                </button>
              ),
            }}
          />
        )}
        <Modal
          isVisible={open}
          title={edit ? `Update ${ptitle}` : `Add ${ptitle}`}
          size="xl"
          content={
            <form onSubmit={edit ? editHandler : submitHandler}>
              <div className="test__InputFlex">
                <Input
                  type="text"
                  onChange={(e) => setStageName(e.target.value)}
                  value={stageName}
                  required={true}
                  title="Stage Name"
                />
                <Select
                  onChange={(e) => setTeamId(e.target.value)}
                  value={teamId}
                  required={true}
                  title="Team"
                  options={teams}
                  filter="teamName"
                  filterValue="id"
                />
                <Textarea
                  onChange={(e) => setEmailTemplate(e.target.value)}
                  value={emailTemplate}
                  required={true}
                  title="Email Template"
                  size="md"
                />
                <Textarea
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  required={true}
                  title="Description"
                  size="md"
                />
                <div className="test__InputContainer">
                  <Button
                    title={edit ? `Update ${ptitle}` : `Add ${ptitle}`}
                    type="submit"
                    loading={loading || uLoading}
                  />
                </div>
              </div>
            </form>
          }
          onClose={() => setOpen(false)}
        />
      </div>
    </div>
  );
};

export default Stages;
