import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Navigation,
  Title,
  Input,
  Select,
  Textarea,
  Button,
} from "../../../components";
import {
  fetchFormPriority,
  fetchFormTickets,
} from "../../../redux/actions/user/ticketsActions";

const Tickets = () => {
  const dispatch = useDispatch();
  const [Comment, setComment] = useState("");
  const [TicketTypeId, setTicketTypeId] = useState("");
  const [TicketName, setTicketName] = useState("");
  const [PriorityLevelId, setPriorityLevelId] = useState("");
  const [file, setFile] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const uploadHandler = (e) => {
    setFile(e.target.files[0]);
  };

  const getTicketType = useSelector((state) => state.getTicketType);
  const { type = [] } = getTicketType;

  const getPriorityReducer = useSelector((state) => state.getPriorityReducer);
  const { prior = [] } = getPriorityReducer;

  useEffect(() => {
    dispatch(fetchFormPriority());
    dispatch(fetchFormTickets());
  }, [dispatch]);
  return (
    <div className="appContainer">
      <Navigation />
      <div className="contentsRight">
        <Title title="Create New Ticket" />
        <form onSubmit={submitHandler}>
          <div className="test__InputFlex">
            <Input
              type="text"
              value={TicketName}
              title="Ticket Name"
              onChange={(e) => setTicketName(e.target.value)}
            />
            <Select
              title="Ticket Type"
              value={TicketTypeId}
              onChange={(e) => setTicketTypeId(e.target.value)}
              options={type}
              filter="ticketTypeName"
              filterValue="id"
            />
            <Select
              title="Priority Level"
              value={PriorityLevelId}
              onChange={(e) => setPriorityLevelId(e.target.value)}
              options={prior}
              filter="level"
              filterValue="id"
            />
            <Input
              type="file"
              value={file}
              title="Upload File"
              onChange={uploadHandler}
            />
            <Textarea
              title="Comment"
              value={Comment}
              onChange={(e) => setComment(e.target.value)}
              //   size="md"
            />
            <div className="test__InputContainer">
              <Button
                type="submit"
                title="Create Ticket"
                //   loading={loading}
                color="teal"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Tickets;
