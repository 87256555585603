export const CREATE_ESTAGE_REQUEST = "CREATE_ESTAGE_REQUEST";
export const CREATE_ESTAGE_SUCCESS = "CREATE_ESTAGE_SUCCESS";
export const CREATE_ESTAGE_FAIL = "CREATE_ESTAGE_FAIL";
export const CREATE_ESTAGE_RESET = "CREATE_ESTAGE_RESET";

export const GET_ESTAGE_REQUEST = "GET_ESTAGE_REQUEST";
export const GET_ESTAGE_SUCCESS = "GET_ESTAGE_SUCCESS";
export const GET_ESTAGE_FAIL = "GET_ESTAGE_FAIL";
export const GET_ESTAGE_RESET = "GET_ESTAGE_RESET";

export const GET_ESTAGES_REQUEST = "GET_ESTAGES_REQUEST";
export const GET_ESTAGES_SUCCESS = "GET_ESTAGES_SUCCESS";
export const GET_ESTAGES_FAIL = "GET_ESTAGES_FAIL";
export const GET_ESTAGES_RESET = "GET_ESTAGES_RESET";

export const UPDATE_ESTAGE_REQUEST = "UPDATE_ESTAGE_REQUEST";
export const UPDATE_ESTAGE_SUCCESS = "UPDATE_ESTAGE_SUCCESS";
export const UPDATE_ESTAGE_FAIL = "UPDATE_ESTAGE_FAIL";
export const UPDATE_ESTAGE_RESET = "UPDATE_ESTAGE_RESET";

export const DELETE_ESTAGE_REQUEST = "DELETE_ESTAGE_REQUEST";
export const DELETE_ESTAGE_SUCCESS = "DELETE_ESTAGE_SUCCESS";
export const DELETE_ESTAGE_FAIL = "DELETE_ESTAGE_FAIL";
export const DELETE_ESTAGE_RESET = "DELETE_ESTAGE_RESET";
