import React, { useEffect } from "react";
import { AdminNavigation, Card, Title } from "../../../components";
import styles from "./styles.module.css";
import {
  FaWindowClose,
  FaTicketAlt,
  FaRegCheckCircle,
  FaSpinner,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTickets,
  getCancelTickets,
  getPendingTickets,
  getSolvedTickets,
} from "../../../redux/actions/user/ticketsActions";

const AdminDashboard = () => {
  const dispatch = useDispatch();

  const allTicket = useSelector((state) => state.allTicket);
  const { created = [] } = allTicket;
  const solvedTicket = useSelector((state) => state.solvedTicket);
  const { solved = [] } = solvedTicket;
  const cancelledTicket = useSelector((state) => state.cancelledTicket);
  const { cancel = [] } = cancelledTicket;
  const pendingTicket = useSelector((state) => state.pendingTicket);
  const { pending = [] } = pendingTicket;

  useEffect(() => {
    dispatch(getAllTickets());
    dispatch(getPendingTickets());
    dispatch(getSolvedTickets());
    dispatch(getCancelTickets());
  }, [dispatch]);
  return (
    <div className="appContainer">
      <AdminNavigation />
      <div className="contentsRight">
        <Title title="Dashboard" type="Admin" />
        <div className={styles.cardFlex}>
          <Card
            count={created.length}
            title="Total Ticket Requests"
            Icon={FaTicketAlt}
            color="crimson"
            primary="red"
            url="/user/tickets"
          />
          <Card
            count={pending.length}
            title="Tickets Resolved"
            Icon={FaSpinner}
            color="gold"
            primary="lightGold"
            url="/user/tickets"
          />
          <Card
            count={solved.length}
            title="Tickets Cancelled"
            Icon={FaRegCheckCircle}
            color="gold"
            primary="lightGold"
            url="/user/tickets"
          />
          <Card
            count={cancel.length}
            title="Pending Tickets"
            Icon={FaWindowClose}
            color="cyan"
            primary="blue"
            url="/user/tickets"
          />
          <Card
            count={cancel.length}
            title="Assigned
            Tickets"
            Icon={FaWindowClose}
            color="cyan"
            primary="blue"
            url="/user/tickets"
          />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
