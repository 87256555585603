export const CREATE_PRIORITY_REQUEST = "CREATE_PRIORITY_REQUEST";
export const CREATE_PRIORITY_SUCCESS = "CREATE_PRIORITY_SUCCESS";
export const CREATE_PRIORITY_FAIL = "CREATE_PRIORITY_FAIL";
export const CREATE_PRIORITY_RESET = "CREATE_PRIORITY_RESET";

export const GET_PRIORITY_REQUEST = "GET_PRIORITY_REQUEST";
export const GET_PRIORITY_SUCCESS = "GET_PRIORITY_SUCCESS";
export const GET_PRIORITY_FAIL = "GET_PRIORITY_FAIL";
export const GET_PRIORITY_RESET = "GET_PRIORITY_RESET";

export const GET_PRIORITYS_REQUEST = "GET_PRIORITYS_REQUEST";
export const GET_PRIORITYS_SUCCESS = "GET_PRIORITYS_SUCCESS";
export const GET_PRIORITYS_FAIL = "GET_PRIORITYS_FAIL";
export const GET_PRIORITYS_RESET = "GET_PRIORITYS_RESET";

export const UPDATE_PRIORITY_REQUEST = "UPDATE_PRIORITY_REQUEST";
export const UPDATE_PRIORITY_SUCCESS = "UPDATE_PRIORITY_SUCCESS";
export const UPDATE_PRIORITY_FAIL = "UPDATE_PRIORITY_FAIL";
export const UPDATE_PRIORITY_RESET = "UPDATE_PRIORITY_RESET";

export const DELETE_PRIORITY_REQUEST = "DELETE_PRIORITY_REQUEST";
export const DELETE_PRIORITY_SUCCESS = "DELETE_PRIORITY_SUCCESS";
export const DELETE_PRIORITY_FAIL = "DELETE_PRIORITY_FAIL";
export const DELETE_PRIORITY_RESET = "DELETE_PRIORITY_RESET";
