import React, { useEffect } from "react";
import { Navigation, Card, Title } from "../../../components";
import styles from "./styles.module.css";
import {
  FaWindowClose,
  FaTicketAlt,
  FaRegCheckCircle,
  FaSpinner,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTickets,
  getCancelTickets,
  getPendingTickets,
  getSolvedTickets,
} from "../../../redux/actions/user/ticketsActions";

const Dashboard = () => {
  const dispatch = useDispatch();

  const allTicket = useSelector((state) => state.allTicket);
  const { created = [] } = allTicket;
  const solvedTicket = useSelector((state) => state.solvedTicket);
  const { solved = [] } = solvedTicket;
  const cancelledTicket = useSelector((state) => state.cancelledTicket);
  const { cancel = [] } = cancelledTicket;
  const pendingTicket = useSelector((state) => state.pendingTicket);
  const { pending = [] } = pendingTicket;

  useEffect(() => {
    dispatch(getAllTickets());
    dispatch(getPendingTickets());
    dispatch(getSolvedTickets());
    dispatch(getCancelTickets());
  }, [dispatch]);
  return (
    <div className="appContainer">
      <Navigation />
      <div className="contentsRight">
        <Title title="Dashboard" />
        <div className={styles.cardFlex}>
          <Card
            count={created.length}
            title="Total Tickets Created"
            Icon={FaTicketAlt}
            color="crimson"
            primary="red"
            url="/user/tickets"
          />
          <Card
            count={pending.length}
            title="Total Tickets Pending"
            Icon={FaSpinner}
            color="gold"
            primary="lightGold"
            url="/user/tickets"
          />
          <Card
            count={solved.length}
            title="Total Tickets Solved"
            Icon={FaRegCheckCircle}
            color="gold"
            primary="lightGold"
            url="/user/tickets"
          />
          <Card
            count={cancel.length}
            title="Total Tickets Cancelled"
            Icon={FaWindowClose}
            color="cyan"
            primary="blue"
            url="/user/tickets"
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
