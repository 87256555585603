import {
  CREATE_TICKET_FAIL,
  CREATE_TICKET_REQUEST,
  CREATE_TICKET_RESET,
  CREATE_TICKET_SUCCESS,
  SOLVED_TICKET_FAIL,
  SOLVED_TICKET_REQUEST,
  SOLVED_TICKET_RESET,
  SOLVED_TICKET_SUCCESS,
  PENDING_TICKET_FAIL,
  PENDING_TICKET_REQUEST,
  PENDING_TICKET_RESET,
  PENDING_TICKET_SUCCESS,
  CANCELLED_TICKET_FAIL,
  CANCELLED_TICKET_REQUEST,
  CANCELLED_TICKET_RESET,
  CANCELLED_TICKET_SUCCESS,
  ALL_TICKET_FAIL,
  ALL_TICKET_REQUEST,
  ALL_TICKET_RESET,
  ALL_TICKET_SUCCESS,
  TICKET_TYPE_FAIL,
  TICKET_TYPE_REQUEST,
  TICKET_TYPE_RESET,
  TICKET_TYPE_SUCCESS,
  PRIORITY_FAIL,
  PRIORITY_REQUEST,
  PRIORITY_RESET,
  PRIORITY_SUCCESS,
} from "../../constants/user/ticketsConstants";

export const createTicketReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_TICKET_REQUEST:
      return { loading: true };
    case CREATE_TICKET_SUCCESS:
      return { loading: false, success: true };
    case CREATE_TICKET_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_TICKET_RESET:
      return {};
    default:
      return state;
  }
};

export const solvedTicketReducer = (state = {}, action) => {
  switch (action.type) {
    case SOLVED_TICKET_REQUEST:
      return { loading: true };
    case SOLVED_TICKET_SUCCESS:
      return { loading: false, success: true, solved: action.payload.data };
    case SOLVED_TICKET_FAIL:
      return { loading: false, error: action.payload };
    case SOLVED_TICKET_RESET:
      return {};
    default:
      return state;
  }
};

export const pendingTicketReducer = (state = {}, action) => {
  switch (action.type) {
    case PENDING_TICKET_REQUEST:
      return { loading: true };
    case PENDING_TICKET_SUCCESS:
      return { loading: false, success: true, pending: action.payload.data };
    case PENDING_TICKET_FAIL:
      return { loading: false, error: action.payload };
    case PENDING_TICKET_RESET:
      return {};
    default:
      return state;
  }
};
export const cancelledTicketReducer = (state = {}, action) => {
  switch (action.type) {
    case CANCELLED_TICKET_REQUEST:
      return { loading: true };
    case CANCELLED_TICKET_SUCCESS:
      return { loading: false, success: true, cancel: action.payload.data };
    case CANCELLED_TICKET_FAIL:
      return { loading: false, error: action.payload };
    case CANCELLED_TICKET_RESET:
      return {};
    default:
      return state;
  }
};
export const allTicketReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_TICKET_REQUEST:
      return { loading: true };
    case ALL_TICKET_SUCCESS:
      return { loading: false, success: true, created: action.payload.data };
    case ALL_TICKET_FAIL:
      return { loading: false, error: action.payload };
    case ALL_TICKET_RESET:
      return {};
    default:
      return state;
  }
};

export const getTicketTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case TICKET_TYPE_REQUEST:
      return { loading: true };
    case TICKET_TYPE_SUCCESS:
      return { loading: false, success: true, type: action.payload.data };
    case TICKET_TYPE_FAIL:
      return { loading: false, error: action.payload };
    case TICKET_TYPE_RESET:
      return {};
    default:
      return state;
  }
};

export const getUPriorityReducer = (state = {}, action) => {
  switch (action.type) {
    case PRIORITY_REQUEST:
      return { loading: true };
    case PRIORITY_SUCCESS:
      return { loading: false, success: true, prior: action.payload.data };
    case PRIORITY_FAIL:
      return { loading: false, error: action.payload };
    case PRIORITY_RESET:
      return {};
    default:
      return state;
  }
};
